import React, {Component} from 'react';

import $ from 'jquery';
import Cookies from 'js-cookie';

import LayoutLPOne from '../components/layoutLPOne';
import SEO from "../components/seo";

import FormField from '../components/utilities/FormField';

import './abm.scss';

class CSPForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            contactName: '',
            contactEmail: '',
            contactPhone: '',
            contactCompany: '',
            contactTitle: '',
            contactRunning: ''
        };

        this.submit = this.submit.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.emailChange = this.emailChange.bind(this);
        this.phoneChange = this.phoneChange.bind(this);
        this.companyChange = this.companyChange.bind(this);
        this.titleChange = this.titleChange.bind(this);
        this.runningChange = this.runningChange.bind(this);
    }

    submit(e) {
        e.preventDefault();
        let formObject = $('#heroForm');
        let postDt = new Date();
        let loadDt = new Date();
        //let _sid = 'qq3eu25egawyabolsjpyzcr5';
        let _sid = Cookies.get('ASP.NET_SessionId');
        if (formObject.find('input[name="js"]').length === 0) {
            let str = '<input type="hidden" name="js" value="1" />';
            formObject.append(str);
        }
        if (formObject.find('input[name="ttp"]').length === 0) {
            let str = '<input type="hidden" name="ttp" value="' + ((postDt - loadDt) / 1000).toString() + '" />';
            formObject.append(str);
        }
        if (formObject.find('input[name="sid"]').length === 0) {
            let str = '<input type="hidden" name="sid" value="' + _sid + '" />';
            formObject.append(str);
        } else {
            formObject.find('input[name="sid"]').val(_sid);
        }

        let post_data = formObject.serialize();

        // console.log(post_data);

        this.setState({
            contactName: '',
            contactEmail: '',
            contactPhone: '',
            contactCompany: '',
            contactTitle: '',
            contactRunning: ''
        });

        $.ajax({
            url: "https://metrix.meritmile.com/email.aspx?ajax=1",
            type: 'POST',
            data: post_data,
            cache: false,
            dataType: 'json',
            success: function(data) {
                // Success..
                // let r = data[0];
                // let redirect_url = "https://www.meritmile.com/pr-agency-florida/";

                // formObject.find('.feedback').show().removeClass('alert-error').addClass('alert-success').html(r.Message).css('display', 'block').focus();
                // window.location.href = redirect_url;

                $('#heroForm').hide();
                $('.form-wrapper .thankyou').show();
                // window.open(
                //     'https://mm-stats.com/bu0py',
                //     '_blank' // <- This is what makes it open in a new tab.
                // );
            },
            // Fail..
            error: function(result) {
                let r = result[0];

                formObject.find('.feedback').show().addClass('alert-error').html(r.Message).css('display', 'block').focus();
                formObject.find('.submit').attr('disabled', false).removeClass('disabled');
            }
        });
    }

    nameChange(e){
        this.setState({contactName: e.target.value})
    }

    emailChange(e){
        this.setState({contactEmail: e.target.value})
    }

    phoneChange(e){
        this.setState({contactPhone: e.target.value})
    }

    companyChange(e){
        this.setState({contactCompany: e.target.value})
    }

    titleChange(e){
        this.setState({contactTitle: e.target.value})
    }

    runningChange(e){
        this.setState({contactRunning: e.target.value})
    }

    render() {
        return (
            <form id="heroForm" name="metrixform" method="POST" action="https://metrix.meritmile.com/email.aspx" onSubmit={this.submit}>
                <FormField fieldID={"namehero"} valChange={this.nameChange} val={this.state.contactName} type={"text"}  name={"name"} label={"Full Name"}/>
                <FormField fieldID={"emailhero"}  valChange={this.emailChange} val={this.state.contactEmail} type={"email"}  name={"email"} label={"Email"}/>
                <FormField fieldID={"companyhero"}  valChange={this.companyChange} val={this.state.contactCompany} type={"text"}  name={"company"} label={"Company"}/>
                <FormField fieldID={"titlehero"}  valChange={this.titleChange} val={this.state.contactTitle} type={"textnr"}  name={"title"} label={"Title"}/>
                <FormField fieldID={"telephonehero"}  valChange={this.phoneChange} val={this.state.contactPhone} type={"tel"} name={"telephone"} label={"Preferred contact number"}/>
                <div className='row' id='radioRow'>
                    <div className="col-12 col-md-6">
                        <p style={{ textAlign: 'left', fontSize: '11px', lineHeight: '12px', marginTop: '10px', marginBottom: '0', paddingBottom: '0' }}>
                            I'm currently planning or running ABM campaigns
                        </p>
                    </div>
                    <div className="col-12 col-md-6">
                        <FormField fieldID={"running"} valChange={this.runningChange} val={this.state.contactRunning} type={"radio"}  name={"running"} label={""} />
                    </div>
                </div>
                
                
                <button type="submit" className="btn btn-white">SUBMIT</button>
                <div className="form-group">
                    <input type="hidden" name="referrer" value="https://www.meritmile.com/account-based-marketing/" />
                    <input type="hidden" name="auth" value="8d352f54-e27c-4f6b-955c-236905204dbb" />
                    <input type="text" className="d-none hu" id="human-check" name="human-check" />
                    <div className="feedback"></div>
                    <div className="fix"></div>
                </div>
            </form>
        )
    }
}

export default ({pageContext}) => (
    <LayoutLPOne>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image}
            link={pageContext.link}
            />
        <div className={`${pageContext.slug} abm-template`}>
            <div className="lp-hero">
                <div className="container margin-b">
                    <div className="row margin-b">
                        <div className="col-lg-7 hero-title">
                            <h1>Turnkey, Fully Managed<br />Account Based Marketing<span className="bar"></span></h1>
                            <p>Increase your marketing and sales pipeline with proven, predictable, and measurable ABM</p>
                            <img src="https://apiold.meritmile.com/wp-content/uploads/2025/03/merit-mile-logo-white.webp" alt="Merit Mile" className="img-responsive" />
                        </div>
                        <div className="col-lg-5 hero-bg">
                            <div className="inside-hero text-center">
                                <h2>I Want to Know More About Fully Managed ABM</h2>
                                <div className="form-wrapper">
                                    <CSPForm />
                                    <div className="thankyou">
                                    <h4>Thank You</h4>
                                    <p>Your submission has been received.</p>
                                    <p>Add info@meritmile.com to your contacts to ensure future communications will not be sent to spam.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="intro">
                <div className="container">
                    <div className="row">
                        <div className="col-12" style={{ marginTop: '4em '}}>
                            <h2 className='text-center'>Increase Your Sales Pipeline With Account Based Marketing</h2>
                            <p>Merit Mile is a globally trusted and proven Account-Based Marketing (ABM) and demand generation marketing agency. Our turnkey, fully managed ABM services are tailored to meet your specific marketing and sales lead generation and awareness goals. We have deep ABM experience across Azure and cloud services, customer experience and contact centers, healthcare, financial services, and manufacturing.</p>
                            <p>Whether you are targeting a few strategic companies with a high-touch ABM program, growing the top of funnel marketing pipeline, or nurturing existing opportunities down the funnel, Merit Mile has the ABM expertise, best practices, and platforms that drive measurable success and guaranteed lead generation.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="graywrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>Proven, Predictable, and Measurable ABM</h2>
                            <p>Successful ABM requires experience, platforms, time, and creative services. That's why Fortune 500 leaders like Microsoft and Cisco rely on Merit Mile for their ABM strategies.</p>
                            <p>Our fully managed, intent-driven ABM lead generation and awareness services are proven, predictable, and measurable. You'll know the goals, tactics, cost per lead (CPL), lead types (MQL, SQL, BANT), numbers, delivery times, and next steps upfront. The process is efficient for your marketing team, allowing you to focus elsewhere while experts manage your ABM campaigns.</p>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: '2em' }}>
                        <div className="col-12">
                            <img src="https://apiold.meritmile.com/wp-content/uploads/2025/03/amb-proof-in-the-results-table.webp" className="img-responsive" alt="" />                            
                        </div>
                    </div>
                    <div className="row">
                        <div class="col-12">
                            <div className="flexLinks">
                                <a href="/case-studies/kddiamerica/">Telco and<br />Cloud</a>
                                <a href="/case-studies/teladoc-health-microsoft/">Healthcare</a>                            
                                <a href="/case-studies/microsoft-nuance/">Customer<br />Experience</a>                            
                                <a href="/case-studies/capgemini/">Azure</a>
                                <a href="/case-studies/finastra-microsoft/">Financial<br />Services</a>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
            <div className="intro" style={{ marginTop: '4em '}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-7">
                            <h2>Engage With Your Top Accounts</h2>
                            <p>ABM is a strategic marketing approach that unites marketing and sales teams to target specific high-value accounts. Unlike broad-reaching campaigns, ABM treats target accounts as markets of one and focuses on personalized campaigns designed to engage organizations based on their specific needs and buying stage.</p>
                            <p>By aligning efforts, ABM drives deeper engagement and higher conversion rates. This method ensures that the most valuable prospects receive tailored marketing and sales initiatives, promoting successful business relationships and accelerating growth.</p>
                            <ul style={{ marginTop: '2em' }}>
                                <li>Deeper engagement</li>
                                <li>Higher conversion rates</li>
                                <li>Specific buying stages</li>
                                <li>Tailored messages and offers</li>
                                <li>Marketing and sales alignment</li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-5 text-center">
                            <img src="https://apiold.meritmile.com/wp-content/uploads/2025/03/abm-engage-top-accounts.webp" alt="Engage With Your Top Accounts" className="img-responsive integration" />
                            <div className='ImageDesc'>
                                Merit Mile ABM campaigns engage your target accounts and buyer at a specific buying stage.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="intro" style={{ marginTop: '2em'}}>
                <div className="container">
                    <div class="row">
                        <div className="col-12">
                            <h2 className='text-center' style={{ marginBottom: '2em'}}>Your ABM Goals Are Unique, Your Campaign Should Be Too</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-7">
                            <img src="https://apiold.meritmile.com/wp-content/uploads/2025/03/abm-logos.webp" alt="ABM Client Logos" className="img-responsive" style={{ marginBottom: '2em'}} />
                        </div>
                        <div className="col-12 col-md-5">
                            <p>Merit Mile offers a complete ABM campaign service that is fully customized and managed to achieve your specific goals and objectives. We apply your marketing and sales goals to a personalized ABM strategy that engages your targets with customized messaging, creative content, calls to action, and offers.</p>
                            <h4 style={{ marginTop: '2em' }}><strong>Our comprehensive ABM services include:</strong></h4>
                            <ul>
                                <li>ABM campaign strategy</li>
                                <li>Campaign messaging and creative</li>
                                <li>Media buys and acquisition costs</li>
                                <li>End-to-end execution</li>
                                <li>Reporting, optimization, insights</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="graywrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>Merit Mile ABM Programs</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <h3>Content Syndication</h3>
                            <ul>
                              <li>Promote your marketing offers to a target account list</li>
                              <li>Predictable and guaranteed number of leads over a specific duration</li>
                              <li>Single or double touch leads</li>
                              <li>Optional profiling questions</li>
                              <li>2-4 months</li>
                            </ul>
                            <div className='box blight'>Good</div>
                        </div>
                        <div className="col-12 col-md-4">
                            <h3>Content Syndication + Awareness</h3>
                            <ul>
                              <li>Creates always-on awareness with display advertising</li>
                              <li>Keyword driven ad placements and retargeting</li>
                              <li>Predictable and guaranteed number of impressions and leads over a specific duration</li>
                              <li>+ Content Syndication</li>
                              <li>3-6 months</li>
                            </ul>
                            <div className='box bmedium'>Better</div>
                        </div>
                        <div className="col-12 col-md-4">
                            <h3>Full Funnel</h3>
                            <ul>
                              <li>Expanded programs to move prospects deeper into the sales funnel</li>
                              <li>Nurture opportunities and retarget over time</li>
                              <li>Higher-touch tactics such premium channels, video, direct mail, or events become activated</li>
                              <li>+ Content Syndication and Awareness</li>
                              <li>6 - 12 months</li>
                            </ul>
                            <div className='box bdark'>Best</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="intro" style={{ marginTop: '4em '}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p>To schedule an in person or virtual meeting with Merit Mile ABM experts, please submit the form, <a href="mailto:Hello@MeritMile.com?subject=Question%20about%20ABM">send us an email here</a>, or call us directly at +1-561-362-8888.</p>
                        </div>
                    </div>      
                </div>
            </div>
            <div className="fullWidthImageWrapper">
                <img
                    src="https://www.meritmile.com/uploads/2021/09/mm-divider.jpg"
                    alt="Merit Mile Icon Divider"
                    className="img-fluid"
                />
            </div>
        </div>
    </LayoutLPOne>
)